/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.
 * OpenAPI spec version: 0.1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export type GetLayerStatsActiveAccountsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * The size of the step between returned statistic windows, in seconds.
The backend supports a limited number of step sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_step_seconds?: number;
};

export type GetLayerStatsTxVolumeParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * The size of windows into which the statistic is grouped, in seconds.
The backend supports a limited number of window sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_size_seconds?: number;
/**
 * The size of the step between returned statistic windows, in seconds.
The backend supports a limited number of step sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_step_seconds?: number;
};

export type GetRuntimeAccountsAddressNftsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * Only return NFT instances from the token contract at the given staking address.
 */
token_address?: EthOrOasisAddress;
};

export type GetRuntimeEvmTokensAddressNftsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetRuntimeEvmTokensAddressHoldersParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetRuntimeEvmTokensParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on the name, the name or symbol must contain this value as a substring.
 */
name?: string;
};

export type GetRuntimeEventsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block round.
 */
block?: number;
/**
 * A filter on transaction index. The returned events all need to originate
from a transaction that appeared in `tx_index`-th position in the block.
It is invalid to specify this filter without also specifying a `block`.
Specifying `tx_index` and `round` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_index?: number;
/**
 * A filter on the hash of the transaction that originated the events.
Specifying `tx_index` and `round` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.
This can be an Ethereum transaction hash; the query will compare against
both a transaction's regular tx_hash and eth_tx_hash (if it exists).

 */
tx_hash?: string;
/**
 * A filter on the event type.
 */
type?: RuntimeEventType;
/**
 * A filter on related accounts. Every returned event will refer to
this account. For example, for a `accounts.Transfer` event, this will be
the sender or the recipient of tokens.

 */
rel?: EthOrOasisAddress;
/**
 * A filter on the evm log signatures.
Note: The filter will only match on parsed (verified) EVM events.

 */
evm_log_signature?: string;
/**
 * A filter on a smart contract. Every returned event will have been
emitted by the contract at this address.

 */
contract_address?: EthOrOasisAddress;
/**
 * A filter on NFT events. Every returned event will be specifically
about this NFT instance ID. You must specify the contract_address
filter with this filter.
Currently this only supports ERC-721 Transfer events.
This may expand to support other event types in the future.
If you want only ERC-721 Transfer events, specify
evm_log_signature=ddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef
to avoid inadvertently getting other event types if they are
supported later.
Using an evm_log_signature filter with this set to any other value
will match no events.

 */
nft_id?: string;
};

export type GetRuntimeTransactionsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block round.
 */
block?: number;
/**
 * A filter on minimum transaction time, inclusive.
 */
after?: string;
/**
 * A filter on maximum transaction time, exclusive.
 */
before?: string;
/**
 * A filter on related accounts. Every returned transaction will refer to
this account in a way. For example, for an `accounts.Transfer` tx, this will be
the sender or the recipient of tokens.
Nexus detects related accounts inside EVM transactions and events on a
best-effort basis. For example, it inspects ERC20 methods inside `evm.Call` txs.

 */
rel?: EthOrOasisAddress;
};

export type GetRuntimeBlocksParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on minimum block height, inclusive.
 */
from?: number;
/**
 * A filter on maximum block height, inclusive.
 */
to?: number;
/**
 * A filter on minimum block time, inclusive.
 */
after?: string;
/**
 * A filter on maximum block time, exclusive.
 */
before?: string;
/**
 * A filter on the block hash.
 */
hash?: string;
};

export type GetConsensusProposalsProposalIdVotesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusProposalsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * Filter on the submitter of the proposal.
 */
submitter?: StakingAddress;
/**
 * Filter on the state of the proposal.
 */
state?: ProposalState;
};

export type GetConsensusEpochsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDebondingDelegationsToParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDebondingDelegationsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDelegationsToParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDelegationsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusValidatorsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on the validator name. Every returned validator will have
a name that is a superstring of the input param.

 */
name?: string;
};

export type GetConsensusEntitiesAddressNodesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusEntitiesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusRoothashMessagesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
runtime: Runtime;
round?: number;
type?: RoothashMessageType;
rel?: StakingAddress;
};

export type GetConsensusEventsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block height.
 */
block?: number;
/**
 * A filter on transaction index. The returned events all need to originate
from a transaction that appeared in `tx_index`-th position in the block.
It is invalid to specify this filter without also specifying a `block`.
Specifying `tx_index` and `block` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_index?: number;
/**
 * A filter on the hash of the transaction that originated the events.
Specifying `tx_index` and `block` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_hash?: string;
/**
 * A filter on related accounts. Every returned event will refer to
this account. For example, for a `Transfer` event, this will be the
the sender or the recipient of tokens.

 */
rel?: StakingAddress;
/**
 * A filter on the event type.
 */
type?: ConsensusEventType;
};

export type GetConsensusTransactionsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block height.
 */
block?: number;
/**
 * A filter on transaction method.
 */
method?: ConsensusTxMethod;
/**
 * A filter on transaction sender.
 */
sender?: StakingAddress;
/**
 * A filter on related accounts.
 */
rel?: string;
/**
 * A filter on minimum transaction time, inclusive.
 */
after?: string;
/**
 * A filter on maximum transaction time, exclusive.
 */
before?: string;
};

export type GetConsensusBlocksParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on minimum block height, inclusive.
 */
from?: number;
/**
 * A filter on maximum block height, inclusive.
 */
to?: number;
/**
 * A filter on minimum block time, inclusive.
 */
after?: string;
/**
 * A filter on maximum block time, exclusive.
 */
before?: string;
/**
 * A filter on the block hash.
 */
hash?: string;
};

/**
 * An empty response indicating that the requested resource was not found.
 */
export type NotFoundErrorResponse = unknown;

export type HumanReadableErrorResponse = {
  /** An error message. */
  msg: string;
};

export interface ActiveAccounts {
  /** The number of active accounts for the 24hour window ending at window_end. */
  active_accounts: number;
  /** The date for the end of the daily active accounts measurement window. */
  window_end: string;
}

/**
 * A list of daily unique active account windows.

 */
export interface ActiveAccountsList {
  window_size_seconds: number;
  /** The list of daily unique active account windows. */
  windows: ActiveAccounts[];
}

export interface TxVolume {
  /** The transaction volume for this window. */
  tx_volume: number;
  /** The end timestamp for this daily transaction volume measurement. */
  window_end: string;
}

/**
 * A list of daily transaction volumes.

 */
export interface TxVolumeList {
  window_size_seconds: number;
  /** The list of daily transaction volumes. */
  windows: TxVolume[];
}

export interface AccountStats {
  /** The total number of transactions this account was involved with. */
  num_txns: number;
  /** The total amount of native tokens received, in base units. */
  total_received?: TextBigInt;
  /** The total amount of native tokens sent, in base units. */
  total_sent?: TextBigInt;
}

export interface EvmNft {
  /** Describes the asset which this NFT represents */
  description?: string;
  /** The instance ID of this NFT within the collection represented by `token`. */
  id: TextBigInt;
  /** A URI pointing to a resource with mime type image/* representing
the asset which this NFT represents. (Additional
non-descriptive text from ERC-721 omitted.)
 */
  image?: string;
  /** A metadata document for this NFT instance.
Currently only ERC-721 is supported, where the document is an Asset Metadata from the ERC721 Metadata JSON Schema.
 */
  metadata?: unknown;
  metadata_accessed?: string;
  metadata_uri?: string;
  /** Identifies the asset which this NFT represents */
  name?: string;
  /** The total number of transfers of this NFT instance.
 */
  num_transfers?: number;
  /** The Oasis address of this NFT instance's owner. */
  owner?: Address;
  /** The Ethereum address of this NFT instance's owner. */
  owner_eth?: string;
  token: EvmToken;
}

/**
 * A list of NFT instances.
 */
export type EvmNftListAllOf = {
  /** A list of L2 EVM NFT (ERC-721, ...) instances. */
  evm_nfts: EvmNft[];
};

export type EvmNftList = List & EvmNftListAllOf;

export interface EvmToken {
  /** The Oasis address of this token's contract. */
  contract_addr: string;
  /** The number of least significant digits in base units that should be displayed as
decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
Affects display only. Often equals 18, to match ETH.
 */
  decimals?: number;
  /** The Ethereum address of this token's contract. */
  eth_contract_addr: string;
  /**
   * Whether the contract has been successfully verified by Sourcify.
Additional information on verified contracts is available via
the `/{runtime}/accounts/{address}` endpoint.
DEPRECATED: This field will be removed in the future in favor of verification_level

   * @deprecated
   */
  is_verified: boolean;
  /** Name of the token, as provided by token contract's `name()` method. */
  name?: string;
  /** The number of addresses that have a nonzero balance of this token.
 */
  num_holders: number;
  /** The total number of transfers of this token.
 */
  num_transfers?: number;
  /** Symbol of the token, as provided by token contract's `symbol()` method. */
  symbol?: string;
  /** The total number of base units available. */
  total_supply?: TextBigInt;
  /** The heuristically determined interface that the token contract implements.
A less specialized variant of the token might be detected; for example, an
ERC-1363 token might be labeled as ERC-20 here. If the type cannot be
detected or is not supported, this field will be null/absent.
 */
  type: EvmTokenType;
  verification_level?: VerificationLevel;
}

/**
 * A list of tokens in a runtime.
 */
export type EvmTokenListAllOf = {
  /** A list of L2 EVM tokens (ERC-20, ERC-721, ...). */
  evm_tokens: EvmToken[];
};

export type EvmTokenList = List & EvmTokenListAllOf;

/**
 * The type of a EVM token.

 */
export type EvmTokenType = typeof EvmTokenType[keyof typeof EvmTokenType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EvmTokenType = {
  ERC20: 'ERC20',
  ERC721: 'ERC721',
} as const;

export interface RuntimeStatus {
  /** The number of compute nodes that are registered and can run the runtime. */
  active_nodes: number;
  /** The height of the most recent indexed block (also sometimes referred to as "round") for this runtime. Query a synced Oasis node for the latest block produced. */
  latest_block: number;
  /** The RFC 3339 formatted consensus time of when the latest indexed block for this runtime was produced. */
  latest_block_time: string;
  /** The number of milliseconds since Nexus processed the latest block. */
  latest_update_age_ms: number;
}

export interface RuntimeAccount {
  /** The staking address for this account. */
  address: string;
  address_preimage?: AddressPreimage;
  /** The balance(s) of this account in this runtime. Most runtimes use only one denomination, and thus
produce only one balance here. These balances do not include "layer (n+1) tokens", i.e. tokens
managed by smart contracts deployed in this runtime. For example, in EVM-compatible runtimes,
this does not include ERC-20 tokens
 */
  balances: RuntimeSdkBalance[];
  /** The balances of this account in each runtime, as managed by EVM smart contracts (notably, ERC-20).
NOTE: This field is limited to 1000 entries. If you need more, please let us know in a GitHub issue.
 */
  evm_balances: RuntimeEvmBalance[];
  /** Data on the EVM smart contract associated with this account address. Only present for accounts
that represent a smart contract on EVM.
 */
  evm_contract?: RuntimeEvmContract;
  stats: AccountStats;
}

export interface RuntimeTransactionEncryptionEnvelope {
  /** The base64-encoded encrypted transaction data. */
  data?: string;
  /** The base64-encoded nonce used to encrypt the transaction data. */
  data_nonce?: string;
  /** The format of the encrypted evm transaction envelope. */
  format: CallFormat;
  /** The base64-encoded public key used to encrypt the transaction. */
  public_key?: string;
  /** The base64-encoded encrypted result data. */
  result?: string;
  /** The base64-encoded nonce used to encrypt the transaction results. */
  result_nonce?: string;
}

/**
 * The method call body. May be null if the transaction was malformed.
 */
export type RuntimeTransactionBody = { [key: string]: unknown };

/**
 * A runtime transaction.

 */
export interface RuntimeTransaction {
  /** A reasonable "amount" associated with this transaction, if
applicable. The meaning varies based on the transaction method.
Usually in native denomination, ParaTime units. As a string.
 */
  amount?: string;
  /** The denomination of the "amount" associated with this transaction, if applicable.
 */
  amount_symbol?: string;
  /** The method call body. May be null if the transaction was malformed. */
  body?: RuntimeTransactionBody;
  /** The fee that was charged for the transaction execution (total, native denomination,
ParaTime base units, as a string).
For EVM transactions this is calculated as `gas_price * gas_used`, where `gas_price = fee / gas_limit`, for compatibility with Ethereum.
For other transactions this equals to `fee`.
 */
  charged_fee: string;
  /** The data relevant to the encrypted transaction. Only present for encrypted
transactions in confidential EVM runtimes like Sapphire.
Note: The term "envelope" in this context refers to the [Oasis-style encryption envelopes](https://github.com/oasisprotocol/oasis-sdk/blob/c36a7ee194abf4ca28fdac0edbefe3843b39bf69/runtime-sdk/src/types/callformat.rs)
which differ slightly from [digital envelopes](hhttps://en.wikipedia.org/wiki/Hybrid_cryptosystem#Envelope_encryption).
 */
  encryption_envelope?: RuntimeTransactionEncryptionEnvelope;
  /** Error details of a failed transaction. */
  error?: TxError;
  /** The Ethereum cryptographic hash of this transaction's encoding.
Absent for non-Ethereum-format transactions.
 */
  eth_hash?: string;
  /** The name of the smart contract function called by the transaction.
Only present for `evm.log` transaction calls to contracts that have been verified.
 */
  evm_fn_name?: string;
  /** The decoded parameters with which the smart contract function was called.
Only present for `evm.log` transaction calls to contracts that have been verified.
 */
  evm_fn_params?: EvmAbiParam[];
  /** The fee that this transaction's sender committed to pay to execute
it (total ParaTime base units, as a string).
 */
  fee: string;
  /** The denomination of the fee.
 */
  fee_symbol: string;
  /** The maximum gas that this transaction's sender committed to use to
execute it.
 */
  gas_limit: number;
  /** The total gas used by the transaction. */
  gas_used: number;
  /** The Oasis cryptographic hash of this transaction's encoding. */
  hash: string;
  /** The 0-based index of this transaction in the block. */
  index: number;
  /** Whether this transaction likely represents a native token transfer.
This is based on a heuristic, and can change at any time without warning and possibly without updating the documentation.
The current heuristic sets this to `true` for:
 - Transactions with method "accounts.Transfer". Those are always native token transfers.
 - Transactions with method "evm.Call" that have no `data` field in their `body`. Those tend to be transfers, but the runtimes provides no reliable visibility into whether a transfer happened.
Note: Other transactions with method "evm.Call", and possibly "evm.Create", may also be (or include) native token transfers. The heuristic will be `false` for those.
 */
  is_likely_native_token_transfer?: boolean;
  /** The method that was called. Defined by the runtime. In theory, this could be any string as the runtimes evolve.
In practice, Nexus currently expects only the following methods:
  - "accounts.Transfer"
  - "consensus.Deposit"
  - "consensus.Withdraw"
  - "consensus.Delegate"
  - "consensus.Undelegate"
  - "evm.Create"
  - "evm.Call"
May be null if the transaction was malformed or encrypted.
 */
  method?: string;
  /** The nonce used with this transaction's 0th signer, to prevent replay. */
  nonce_0: number;
  /** The block round at which this transaction was executed. */
  round: number;
  /** The Oasis address of this transaction's 0th signer.
Unlike Ethereum, Oasis natively supports multiple-signature transactions.
However, the great majority of transactions only have a single signer in practice.
Retrieving the other signers is currently not supported by this API.
 */
  sender_0: Address;
  /** The Ethereum address of this transaction's 0th signer.
 */
  sender_0_eth?: string;
  /** The total byte size of the transaction. */
  size: number;
  /** Whether this transaction successfully executed.
Can be absent (meaning "unknown") for confidential runtimes.
 */
  success?: boolean;
  /** The second-granular consensus time when this tx's block was proposed. */
  timestamp: string;
  /** A reasonable "to" Oasis address associated with this transaction,
if applicable. The meaning varies based on the transaction method. Some notable examples:
  - For `method = "accounts.Transfer"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Deposit"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Withdraw"`, this is the consensus (!) account receiving the funds.
  - For `method = "consensus.Delegate"`, this is the consensus (!) account receiving the funds.
  - For `method = "consensus.Undelegate"`, this is the consensus (!) account to which funds were previously delegated. Note that this corresponds with the `.from` field in the transaction body.
  - For `method = "evm.Create"`, this is the address of the newly created smart contract.
  - For `method = "evm.Call"`, this is the address of the called smart contract
 */
  to?: Address;
  /** A reasonable "to" Ethereum address associated with this transaction,
 */
  to_eth?: string;
}

/**
 * A list of runtime transactions.

 */
export type RuntimeTransactionListAllOf = {
  transactions: RuntimeTransaction[];
};

export type RuntimeTransactionList = List & RuntimeTransactionListAllOf;

export type RuntimeEvmContractVerificationSourceFilesItem = { [key: string]: unknown };

/**
 * The smart contract's [metadata.json](https://docs.soliditylang.org/en/latest/metadata.html) file in JSON format as defined by Solidity.
Includes the smart contract's [ABI](https://docs.soliditylang.org/en/develop/abi-spec.html).

 */
export type RuntimeEvmContractVerificationCompilationMetadata = { [key: string]: unknown };

/**
 * The level of verification of a smart contract, as defined by Sourcify.
An absence of this field means that the contract has not been verified.
See also https://docs.sourcify.dev/docs/full-vs-partial-match/

 */
export type VerificationLevel = typeof VerificationLevel[keyof typeof VerificationLevel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationLevel = {
  partial: 'partial',
  full: 'full',
} as const;

export interface RuntimeEvmContractVerification {
  /** The smart contract's [metadata.json](https://docs.soliditylang.org/en/latest/metadata.html) file in JSON format as defined by Solidity.
Includes the smart contract's [ABI](https://docs.soliditylang.org/en/develop/abi-spec.html).
 */
  compilation_metadata?: RuntimeEvmContractVerificationCompilationMetadata;
  /** Array of all contract source files, in JSON format as returned by [Sourcify](https://sourcify.dev/server/api-docs/#/Repository/get_files_any__chain___address_).
 */
  source_files?: RuntimeEvmContractVerificationSourceFilesItem[];
  verification_level?: VerificationLevel;
}

export interface RuntimeEvmContract {
  /** The creation bytecode of the smart contract. This includes the constructor logic
and the constructor parameters. When run, this code generates the runtime bytecode.
Can be omitted for contracts that were created by another contract, as opposed
to a direct `Create` call.
 */
  creation_bytecode?: string;
  /** The Oasis cryptographic hash of the transaction that created the smart contract.
Can be omitted for contracts that were created by another contract, as opposed
to a direct `Create` call.
 */
  creation_tx?: string;
  /** The Ethereum transaction hash of the transaction in `creation_tx`.
Encoded as a lowercase hex string.
 */
  eth_creation_tx?: string;
  /** The total amount of gas used to create or call this contract. */
  gas_used: number;
  /** The runtime bytecode of the smart contract. This is the code stored on-chain that
describes a smart contract. Every contract has this info, but Nexus fetches
it separately, so the field may be missing for very fresh contracts (or if the fetching
process is stalled).
 */
  runtime_bytecode?: string;
  /** Additional information obtained from contract verification. Only available for smart
contracts that have been verified successfully by Sourcify.
 */
  verification?: RuntimeEvmContractVerification;
}

/**
 * Details about the EVM token involved in the event, if any.

 */
export interface EvmEventToken {
  /** The number of least significant digits in base units that should be displayed as
decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
Affects display only. Often equals 18, to match ETH.
 */
  decimals?: number;
  /** Symbol of the token, as provided by token contract's `symbol()` method. */
  symbol?: string;
  type?: EvmTokenType;
}

/**
 * A decoded parameter of an event or error emitted from an EVM runtime.
Values of EVM type `int128`, `uint128`, `int256`, `uint256`, `fixed`, and `ufixed` are represented as strings.
Values of EVM type `address` and `address payable` are represented as lowercase hex strings with a "0x" prefix.
Values of EVM type `bytes` and `bytes<N>` are represented as base64 strings.
Values of other EVM types (integer types, strings, arrays, etc.) are represented as their JSON counterpart.

 */
export interface EvmAbiParam {
  /** The solidity type of the parameter. */
  evm_type: string;
  /** The parameter name. */
  name: string;
  /** The parameter value. */
  value: unknown;
}

export type RuntimeEventType = typeof RuntimeEventType[keyof typeof RuntimeEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuntimeEventType = {
  accountstransfer: 'accounts.transfer',
  accountsburn: 'accounts.burn',
  accountsmint: 'accounts.mint',
  consensus_accountsdeposit: 'consensus_accounts.deposit',
  consensus_accountswithdraw: 'consensus_accounts.withdraw',
  consensus_accountsdelegate: 'consensus_accounts.delegate',
  consensus_accountsundelegate_start: 'consensus_accounts.undelegate_start',
  consensus_accountsundelegate_done: 'consensus_accounts.undelegate_done',
  coregas_used: 'core.gas_used',
  evmlog: 'evm.log',
} as const;

/**
 * The decoded event contents, possibly augmented with additional address info.
This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`. For object fields that specify an oasis-style address, Nexus
will add a field specifying the corresponding Ethereum address, if known. Currently,
the only such possible fields are `from_eth`, `to_eth`, and `owner_eth`.

 */
export type RuntimeEventBody = { [key: string]: unknown };

/**
 * An event emitted by the runtime layer
 */
export interface RuntimeEvent {
  /** The decoded event contents, possibly augmented with additional address info.
This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`. For object fields that specify an oasis-style address, Nexus
will add a field specifying the corresponding Ethereum address, if known. Currently,
the only such possible fields are `from_eth`, `to_eth`, and `owner_eth`.
 */
  body: RuntimeEventBody;
  /** Ethereum trasnsaction hash of this event's originating transaction.
Absent if the event did not originate from an EVM transaction.
 */
  eth_tx_hash?: string;
  /** If the event type is `evm.log`, this field describes the human-readable type of
evm event, e.g. `Transfer`.
Absent if the event type is not `evm.log`.
 */
  evm_log_name?: string;
  /** The decoded `evm.log` event data.
Absent if the event type is not `evm.log`.
 */
  evm_log_params?: EvmAbiParam[];
  evm_token?: EvmEventToken;
  /** The block height at which this event was generated. */
  round: number;
  /** The second-granular consensus time of this event's block.
 */
  timestamp: string;
  /** Hash of this event's originating transaction.
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string;
  /** 0-based index of this event's originating transaction within its block.
Absent if the event did not originate from a transaction.
 */
  tx_index?: number;
  /** The type of the event. */
  type: RuntimeEventType;
}

/**
 * A list of runtime events.

 */
export type RuntimeEventListAllOf = {
  events: RuntimeEvent[];
};

export type RuntimeEventList = List & RuntimeEventListAllOf;

/**
 * A ParaTime block.

 */
export interface RuntimeBlock {
  /** The total gas used by all transactions in the block. */
  gas_used: number;
  /** The block header hash. */
  hash: string;
  /** The number of transactions in the block. */
  num_transactions: number;
  /** The block round. */
  round: number;
  /** The total byte size of all transactions in the block. */
  size: number;
  /** The second-granular consensus time. */
  timestamp: string;
}

/**
 * A list of consensus blocks.

 */
export type RuntimeBlockListAllOf = {
  blocks: RuntimeBlock[];
};

export type RuntimeBlockList = List & RuntimeBlockListAllOf;

export interface ProposalVote {
  /** The staking address casting this vote. */
  address: string;
  /** The block height at which this vote was recorded. */
  height?: number;
  /** The second-granular consensus time of the block in which this vote was cast. */
  timestamp?: string;
  /** The vote cast. */
  vote: string;
}

/**
 * A list of votes for a governance proposal.

 */
export type ProposalVotesAllOf = {
  /** The unique identifier of the proposal. */
  proposal_id: number;
  /** The list of votes for the proposal. */
  votes: ProposalVote[];
};

/**
 * The state of the proposal.
 */
export type ProposalState = typeof ProposalState[keyof typeof ProposalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProposalState = {
  active: 'active',
  passed: 'passed',
  failed: 'failed',
  rejected: 'rejected',
} as const;

/**
 * The target propotocol versions for this upgrade proposal.
 */
export interface ProposalTarget {
  consensus_protocol?: string;
  runtime_committee_protocol?: string;
  runtime_host_protocol?: string;
}

/**
 * A governance proposal.

 */
export interface Proposal {
  /** The proposal to cancel, if this proposal proposes
cancelling an existing proposal.
 */
  cancels?: number;
  /** The epoch at which voting for this proposal will close. */
  closes_at: number;
  /** The epoch at which this proposal was created. */
  created_at: number;
  /** The deposit attached to this proposal. */
  deposit: TextBigInt;
  /** The epoch at which the proposed upgrade will happen. */
  epoch?: number;
  /** The name of the upgrade handler. */
  handler?: string;
  /** The unique identifier of the proposal. */
  id: number;
  /** The number of invalid votes for this proposal, after tallying.
 */
  invalid_votes: TextBigInt;
  /** The parameters change proposal body. This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go) of oasis-core. This object will conform to one of the `ConsensusParameterChanges` types, depending on the `parameters_change_module`. */
  parameters_change?: unknown;
  /** The name of the module whose parameters are to be changed
by this 'parameters_change' proposal.
 */
  parameters_change_module?: string;
  state: ProposalState;
  /** The staking address of the proposal submitter. */
  submitter: string;
  target?: ProposalTarget;
}

/**
 * A list of governance proposals.

 */
export type ProposalListAllOf = {
  proposals: Proposal[];
};

export type ProposalList = List & ProposalListAllOf;

/**
 * A consensus epoch.

 */
export interface Epoch {
  /** The (inclusive) height at which this epoch ended. Omitted if the epoch is still active. */
  end_height?: number;
  /** The epoch number. */
  id: number;
  /** The (inclusive) height at which this epoch started. */
  start_height: number;
}

/**
 * A list of consensus epochs.

 */
export type EpochListAllOf = {
  epochs: Epoch[];
};

export type EpochList = List & EpochListAllOf;

export interface Allowance {
  /** The allowed account. */
  address: string;
  /** The amount allowed for the allowed account.
This field is omitted when listing multiple accounts.
 */
  amount: TextBigInt;
}

/**
 * A consensus layer account.

 */
export interface Account {
  /** The staking address for this account. */
  address: string;
  /** The allowances made by this account.
This field is omitted when listing multiple accounts.
 */
  allowances: Allowance[];
  /** The available balance, in base units. */
  available: TextBigInt;
  /** The debonding escrow balance, in base units. */
  debonding: TextBigInt;
  /** The balance of this accounts' (outgoing) debonding delegations, in base units. */
  debonding_delegations_balance: TextBigInt;
  /** The balance of this accounts' (outgoing) delegations, in base units. */
  delegations_balance: TextBigInt;
  /** The active escrow balance, in base units. */
  escrow: TextBigInt;
  /** The second-granular consensus time of the block in which this account was first active.
Dates before Cobalt (2021-04-28) are approximate.
 */
  first_activity?: string;
  /** The expected nonce for the next transaction (= last used nonce + 1) */
  nonce: number;
  stats: AccountStats;
}

/**
 * Balance of an account for a specific (implied) runtime and token.

 */
export interface BareTokenHolder {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The Ethereum address of the same account holder, if meaningfully defined. */
  eth_holder_address?: string;
  /** The Oasis address of the account holder. */
  holder_address: string;
}

/**
 * A list of token holders for a specific (implied) runtime and token.

 */
export type TokenHolderListAllOf = {
  holders: BareTokenHolder[];
};

export type TokenHolderList = List & TokenHolderListAllOf;

/**
 * Balance of an account for a specific runtime and EVM token.
 */
export interface RuntimeEvmBalance {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The Oasis address of this token's contract. */
  token_contract_addr: string;
  /** The EVM address of this token's contract. */
  token_contract_addr_eth: string;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
  /** The name of the token. Not guaranteed to be unique across distinct EVM tokens. */
  token_name?: string;
  /** The token ticker symbol. Not guaranteed to be unique across distinct EVM tokens. */
  token_symbol?: string;
  token_type: EvmTokenType;
}

/**
 * Balance of an account for a specific runtime and oasis-sdk token (e.g. ROSE).
 */
export interface RuntimeSdkBalance {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
  /** The token ticker symbol. Unique across all oasis-sdk tokens in the same runtime. */
  token_symbol: string;
}

export type AddressDerivationContext = typeof AddressDerivationContext[keyof typeof AddressDerivationContext];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressDerivationContext = {
  'oasis-core/address:_staking': 'oasis-core/address: staking',
  'oasis-runtime-sdk/address:_secp256k1eth': 'oasis-runtime-sdk/address: secp256k1eth',
  'oasis-runtime-sdk/address:_sr25519': 'oasis-runtime-sdk/address: sr25519',
  'oasis-runtime-sdk/address:_multisig': 'oasis-runtime-sdk/address: multisig',
  'oasis-runtime-sdk/address:_module': 'oasis-runtime-sdk/address: module',
  'oasis-runtime-sdk/address:_runtime': 'oasis-runtime-sdk/address: runtime',
} as const;

/**
 * The data from which a consensus-style address (`oasis1...`)
was derived. Notably, for EVM runtimes like Sapphire,
this links the Oasis address and the Ethereum address.

Oasis addresses are derived from a piece of data, such as an ed25519
public key or an Ethereum address. For example, [this](https://github.com/oasisprotocol/oasis-sdk/blob/b37e6da699df331f5a2ac62793f8be099c68469c/client-sdk/go/helpers/address.go#L90-L91)
is how an Ethereum is converted to an Oasis address. The type of underlying data usually also
determines how the signatuers for this address are verified.

Consensus supports only "staking addresses" (`context="oasis-core/address: staking"`
below; always ed25519-backed).
Runtimes support all types. This means that every consensus address is also
valid in every runtime. For example, in EVM runtimes, you can use staking
addresses, but only with Oasis tools (e.g. a wallet); EVM contracts such as
ERC20 tokens or tools such as Metamask cannot interact with staking addresses.

 */
export interface AddressPreimage {
  /** The base64-encoded data from which the Oasis address was derived.
When `context = "oasis-runtime-sdk/address: secp256k1eth"`, this
is the Ethereum address (in base64, not hex!).
 */
  address_data: string;
  /** The method by which the Oasis address was derived from `address_data`.
 */
  context: AddressDerivationContext;
  /** Version of the `context`. */
  context_version?: number;
}

/**
 * A list of consensus layer accounts.

 */
export type AccountListAllOf = {
  accounts: Account[];
};

export type AccountList = List & AccountListAllOf;

/**
 * A node registered at the consensus layer.

 */
export interface Node {
  /** The unique identifier of this node as a consensus member */
  consensus_pubkey: string;
  /** The public key identifying the entity controlling this node.
 */
  entity_id: string;
  /** The epoch in which this node's commitment expires. */
  expiration: number;
  /** The public key identifying this node. */
  id: string;
  /** The unique identifier of this node on the P2P transport. */
  p2p_pubkey: string;
  /** A bitmask representing this node's roles. */
  roles: string;
  /** The public key that will be used for establishing TLS connections
upon rotation.
 */
  tls_next_pubkey: string;
  /** The public key used for establishing TLS connections. */
  tls_pubkey: string;
}

/**
 * A list of nodes registered at the consensus layer.

 */
export type NodeListAllOf = {
  entity_id: string;
  nodes: Node[];
};

export type NodeList = List & NodeListAllOf;

export interface Escrow {
  /** The amount of tokens that are delegated to this validator account, and are NOT in the process of debonding. */
  active_balance?: TextBigInt;
  /** The shares of tokens that are delegated to this validator account, and are NOT in the process of debonding. */
  active_shares?: TextBigInt;
  /** The amount of tokens that are delegated to this validator account, but are also in the process of debonding (i.e. they will be unstaked within ~2 weeks). */
  debonding_balance?: TextBigInt;
  /** The shares of tokens that are delegated to this validator account, but are also in the process of debonding (i.e. they will be unstaked within ~2 weeks). */
  debonding_shares?: TextBigInt;
  /** The number of accounts that have delegated token to this account. */
  num_delegators?: number;
  /** The amount of token this validator has delegated to itself, and are NOT in the process of debonding. */
  self_delegation_balance?: TextBigInt;
  /** The shares of tokens this validator has delegated to itself, and are NOT in the process of debonding. */
  self_delegation_shares?: TextBigInt;
}

export interface ValidatorMedia {
  /** An email address associated with the entity. */
  email?: string;
  /** Keybase handle. */
  keybase?: string;
  /** URL to a logo image for the entity. */
  logoUrl?: string;
  /** The human-readable name of this entity. */
  name?: string;
  /** Twitter handle. */
  twitter?: string;
  /** An URL associated with the entity. */
  url?: string;
}

export interface ValidatorCommissionBound {
  epoch_end: number;
  epoch_start: number;
  lower: number;
  upper: number;
}

/**
 * An validator registered at the consensus layer.

 */
export interface Validator {
  /** Whether the entity has a node that is registered for being a validator, node is up to date, and has successfully registered itself. It may or may not be part of validator set. */
  active: boolean;
  current_commission_bound: ValidatorCommissionBound;
  /** Commission rate. */
  current_rate: number;
  /** The staking address identifying this Validator. */
  entity_address: string;
  /** The public key identifying this Validator. */
  entity_id: string;
  /** The escrow account data for this validator. */
  escrow: Escrow;
  /** Whether the entity is part of the validator set (top <scheduler.params.max_validators> by stake among active entities). */
  in_validator_set: boolean;
  media?: ValidatorMedia;
  /** The public key identifying this Validator's node. */
  node_id?: string;
  /** The rank of the Validator, determined by voting power. */
  rank: number;
  /** The second-granular consensus time. */
  start_date: string;
  /** The voting power of this Validator. */
  voting_power: number;
  /** The total voting power across all Validators. */
  voting_power_total: number;
}

/**
 * A list of validators registered at the consensus layer.

 */
export type ValidatorListAllOf = {
  validators: Validator[];
};

export type ValidatorList = List & ValidatorListAllOf;

/**
 * An entity registered at the consensus layer.

 */
export interface Entity {
  /** The staking address belonging to this entity; derived from the entity's public key. */
  address: string;
  /** The public key identifying this entity. */
  id: string;
  /** The vector of nodes owned by this entity. */
  nodes: string[];
}

/**
 * A list of entities registered at the consensus layer.

 */
export type EntityListAllOf = {
  entities: Entity[];
};

export type EntityList = List & EntityListAllOf;

/**
 * The "body" of a message is a structure within the
`github.com/oasisprotocol/oasis-core/go/roothash/api/message`
`Message` structure
(https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/roothash/api/message#Message).
For example, if the type is `staking.withdraw`, the body is the Go
`Message` structure's `.Staking.Withdraw` field, which is a
`github.com/oasisprotocol/oasis-core/go/staking/api` `Withdraw`
structure
(https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/staking/api#Withdraw),
with `from` and `amount` fields in JSON.

 */
export type RoothashMessageBody = { [key: string]: unknown };

export interface RoothashMessage {
  /** The "body" of a message is a structure within the
`github.com/oasisprotocol/oasis-core/go/roothash/api/message`
`Message` structure
(https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/roothash/api/message#Message).
For example, if the type is `staking.withdraw`, the body is the Go
`Message` structure's `.Staking.Withdraw` field, which is a
`github.com/oasisprotocol/oasis-core/go/staking/api` `Withdraw`
structure
(https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/staking/api#Withdraw),
with `from` and `amount` fields in JSON.
 */
  body?: RoothashMessageBody;
  /** If executing this message resulted in an error, this is the
error's code.
 */
  error_code?: number;
  /** If executing this message resulted in an error, this is the
error's module.
 */
  error_module?: string;
  /** The 0-based index of this message in the block.
 */
  index: number;
  /** A result value that consensus provided after executing this
message. These aren't centrally registered anywhere, so look at
the consensus apps' `ExecuteMessage`
(https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/cometbft/api#MessageSubscriber)
implementations to see what they return. For example, a
`staking.withdraw` type message gives a
`github.com/oasisprotocol/oasis-core/go/staking/api`
`WithdrawResult` structure as its result
(`https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/staking/api#WithdrawResult`)
with `owner`, `beneficiary`, `allowance`, and `amount_change`
fields.
 */
  result?: unknown;
  /** The block round when the runtime sent this message.
 */
  round: number;
  /** The runtime that sent this message.
 */
  runtime: string;
  /** The type of thies message.
 */
  type?: RoothashMessageType;
}

export type RoothashMessageListAllOf = {
  roothash_messages: RoothashMessage[];
};

export type RoothashMessageList = List & RoothashMessageListAllOf;

export type RoothashMessageType = typeof RoothashMessageType[keyof typeof RoothashMessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoothashMessageType = {
  stakingtransfer: 'staking.transfer',
  stakingwithdraw: 'staking.withdraw',
  stakingadd_escrow: 'staking.add_escrow',
  stakingreclaim_escrow: 'staking.reclaim_escrow',
  registryupdate_runtime: 'registry.update_runtime',
  governancecast_vote: 'governance.cast_vote',
  governancesubmit_proposal: 'governance.submit_proposal',
} as const;

/**
 * The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`

 */
export type ConsensusEventBody = { [key: string]: unknown };

/**
 * A list of consensus events.

 */
export type ConsensusEventListAllOf = {
  events: ConsensusEvent[];
};

export type ConsensusEventList = List & ConsensusEventListAllOf;

export type ConsensusEventType = typeof ConsensusEventType[keyof typeof ConsensusEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusEventType = {
  governanceproposal_executed: 'governance.proposal_executed',
  governanceproposal_finalized: 'governance.proposal_finalized',
  governanceproposal_submitted: 'governance.proposal_submitted',
  governancevote: 'governance.vote',
  registryentity: 'registry.entity',
  registrynode_unfrozen: 'registry.node_unfrozen',
  registrynode: 'registry.node',
  registryruntime: 'registry.runtime',
  registryruntime_suspended: 'registry.runtime_suspended',
  roothashexecution_discrepancy: 'roothash.execution_discrepancy',
  roothashexecutor_committed: 'roothash.executor_committed',
  roothashfinalized: 'roothash.finalized',
  roothashmessage: 'roothash.message',
  roothashin_msg_processed: 'roothash.in_msg_processed',
  stakingallowance_change: 'staking.allowance_change',
  stakingburn: 'staking.burn',
  stakingescrowadd: 'staking.escrow.add',
  stakingescrowdebonding_start: 'staking.escrow.debonding_start',
  stakingescrowreclaim: 'staking.escrow.reclaim',
  stakingescrowtake: 'staking.escrow.take',
  stakingtransfer: 'staking.transfer',
} as const;

/**
 * An event emitted by the consensus layer.

 */
export interface ConsensusEvent {
  /** The block height at which this event was generated. */
  block: number;
  /** The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`
 */
  body: ConsensusEventBody;
  /** The runtime to which the event relates.
Present only for events of type `roothash.*`.
 */
  roothash_runtime?: Runtime;
  /** The ID of the runtime to which the event relates, encoded in hex.
Present only for events of type `roothash.*`.
 */
  roothash_runtime_id?: string;
  /** When applicable, the round in the runtime to which this event
relates.
Present only for events of type `roothash.*` except for
`roothash.execution_discrepancy` before Eden.
 */
  roothash_runtime_round?: number;
  /** Hash of this event's originating transaction.
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string;
  /** 0-based index of this event's originating transaction within its block.
Absent if the event did not originate from a transaction.
 */
  tx_index?: number;
  /** The type of the event. */
  type: ConsensusEventType;
}

export interface TxError {
  /** The status code of a failed transaction. */
  code: number;
  /** The message of a failed transaction.
This field, like `code` and `module`, can represent an error that originated
anywhere in the paratime, i.e. either inside or outside a smart contract.

A common special case worth calling out: When the paratime is
EVM-compatible (e.g. Emerald or Sapphire) and the error originates
inside a smart contract (using `revert` in solidity), the following
will be true:
- `module` will be "evm" and `code` will be 8; see [here](https://github.com/oasisprotocol/oasis-sdk/blob/runtime-sdk/v0.8.3/runtime-sdk/modules/evm/src/lib.rs#L128) for other possible errors in the `evm` module.
- `message` will contain the best-effort human-readable revert reason.
 */
  message?: string;
  /** The module of a failed transaction. */
  module?: string;
  /** The error parameters, as decoded using the contract abi. Present only when
- the error originated from within a smart contract (e.g. via `revert` in Solidity), and
- the contract is verified or the revert reason is a plain String.
If this field is present, `message` will include the name of the error, e.g. 'InsufficentBalance'.
Note that users should be cautious when evaluating error data since the
data origin is not tracked and error information can be faked.
 */
  revert_params?: EvmAbiParam[];
}

/**
 * The method call body. This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go) of oasis-core. This object will conform to one of the types passed to variable instantiations using `NewMethodName` two levels down the hierarchy, e.g. `MethodTransfer` from `oasis-core/go/staking/api` seen [here](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go@v0.2300.10/staking/api#pkg-variables).
 */
export type TransactionBody = { [key: string]: unknown };

/**
 * A consensus transaction.

 */
export interface Transaction {
  /** The block height at which this transaction was executed. */
  block: number;
  /** The method call body. This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go) of oasis-core. This object will conform to one of the types passed to variable instantiations using `NewMethodName` two levels down the hierarchy, e.g. `MethodTransfer` from `oasis-core/go/staking/api` seen [here](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go@v0.2300.10/staking/api#pkg-variables). */
  body: TransactionBody;
  /** Error details of a failed transaction. */
  error?: TxError;
  /** The fee that this transaction's sender committed
to pay to execute it.
 */
  fee: TextBigInt;
  /** The maximum gas that a transaction can use.
 */
  gas_limit: TextBigInt;
  /** The cryptographic hash of this transaction's encoding. */
  hash: string;
  /** 0-based index of this transaction in its block */
  index: number;
  /** The method that was called. */
  method: ConsensusTxMethod;
  /** The nonce used with this transaction, to prevent replay. */
  nonce: number;
  /** The address of who sent this transaction. */
  sender: string;
  /** Whether this transaction successfully executed. */
  success: boolean;
  /** The second-granular consensus time of this tx's block, i.e. roughly when the
[block was proposed](https://github.com/tendermint/tendermint/blob/v0.34.x/spec/core/data_structures.md#header).
 */
  timestamp: string;
}

/**
 * A list of consensus transactions.

 */
export type TransactionListAllOf = {
  transactions: Transaction[];
};

export type TransactionList = List & TransactionListAllOf;

export type ConsensusTxMethod = typeof ConsensusTxMethod[keyof typeof ConsensusTxMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusTxMethod = {
  beaconPVSSCommit: 'beacon.PVSSCommit',
  beaconPVSSReveal: 'beacon.PVSSReveal',
  beaconVRFProve: 'beacon.VRFProve',
  consensusMeta: 'consensus.Meta',
  governanceCastVote: 'governance.CastVote',
  governanceSubmitProposal: 'governance.SubmitProposal',
  keymanagerPublishEphemeralSecret: 'keymanager.PublishEphemeralSecret',
  keymanagerPublishMasterSecret: 'keymanager.PublishMasterSecret',
  keymanagerUpdatePolicy: 'keymanager.UpdatePolicy',
  registryDeregisterEntity: 'registry.DeregisterEntity',
  registryProveFreshness: 'registry.ProveFreshness',
  registryRegisterEntity: 'registry.RegisterEntity',
  registryRegisterNode: 'registry.RegisterNode',
  registryRegisterRuntime: 'registry.RegisterRuntime',
  registryUnfreezeNode: 'registry.UnfreezeNode',
  roothashEvidence: 'roothash.Evidence',
  roothashExecutorCommit: 'roothash.ExecutorCommit',
  roothashExecutorProposerTimeout: 'roothash.ExecutorProposerTimeout',
  roothashSubmitMsg: 'roothash.SubmitMsg',
  stakingAddEscrow: 'staking.AddEscrow',
  stakingAllow: 'staking.Allow',
  stakingAmendCommissionSchedule: 'staking.AmendCommissionSchedule',
  stakingBurn: 'staking.Burn',
  stakingReclaimEscrow: 'staking.ReclaimEscrow',
  stakingTransfer: 'staking.Transfer',
  stakingWithdraw: 'staking.Withdraw',
  'keymanager/churpApply': 'keymanager/churp.Apply',
  'keymanager/churpConfirm': 'keymanager/churp.Confirm',
  'keymanager/churpCreate': 'keymanager/churp.Create',
  'keymanager/churpUpdate': 'keymanager/churp.Update',
  vaultAuthorizeAction: 'vault.AuthorizeAction',
  vaultCancelAction: 'vault.CancelAction',
  vaultCreate: 'vault.Create',
} as const;

/**
 * A debonding delegation.

 */
export interface DebondingDelegation {
  /** The amount of tokens delegated in base units. */
  amount: TextBigInt;
  /** The epoch at which the debonding ends. */
  debond_end: number;
  /** The delegator address. */
  delegator: string;
  /** The shares of tokens delegated. */
  shares: TextBigInt;
  /** The delegatee (validator) address. */
  validator: string;
}

/**
 * A list of debonding delegations.

 */
export type DebondingDelegationListAllOf = {
  debonding_delegations: DebondingDelegation[];
};

export type DebondingDelegationList = List & DebondingDelegationListAllOf;

/**
 * A delegation.

 */
export interface Delegation {
  /** The amount of tokens delegated in base units. */
  amount: TextBigInt;
  /** The delegator address. */
  delegator: string;
  /** The shares of tokens delegated. */
  shares: TextBigInt;
  /** The delegatee (validator) address. */
  validator: string;
}

/**
 * A list of delegations.

 */
export type DelegationListAllOf = {
  delegations: Delegation[];
};

export type DelegationList = List & DelegationListAllOf;

/**
 * A consensus block.

 */
export interface Block {
  /** The epoch number of the epoch in which the block was produced. */
  epoch: number;
  /** The gas limit for the block. A gas limit 0 means that the max block gas was unlimited.
Blocks from Cobalt, Damask, and early Eden had no gas limit as their sizes were only
restricted by byte size until an upgrade during Eden introduced a gas limit.
 */
  gas_limit: TextBigInt;
  /** The block header hash. */
  hash: string;
  /** The block height. */
  height: number;
  /** Number of transactions in the block. */
  num_transactions: number;
  /** The size limit for the block in bytes.
 */
  size_limit?: TextBigInt;
  /** The Merkle root of the state tree after applying the block. */
  state_root: string;
  /** The second-granular consensus time. */
  timestamp: string;
}

export type BlockListAllOf = {
  blocks: Block[];
};

export interface Status {
  /** The height of the most recent indexed block. Compare with latest_node_block to measure
how far behind Nexus is from the chain.
 */
  latest_block: number;
  /** The RFC 3339 formatted consensus time of when the most recently indexed block was produced. */
  latest_block_time: string;
  /** The height of the most recently produced block on-chain as seen by Nexus. */
  latest_node_block: number;
  /** The number of milliseconds since Nexus processed the latest block. */
  latest_update_age_ms: number;
}

export interface List {
  /** Whether total_count is clipped for performance reasons. */
  is_total_count_clipped: boolean;
  /** The total number of records that match the query, i.e. the number of records
the query would return with limit=infinity.
 */
  total_count: number;
}

export type ProposalVotes = List & ProposalVotesAllOf;

/**
 * A list of consensus blocks.

 */
export type BlockList = List & BlockListAllOf;

export type CallFormat = string;

/**
 * A base64-encoded ed25519 public key.
 */
export type Ed25519PubKey = string;

/**
 * @pattern ^oasis1[a-z0-9]{40}$|^(0x)?[0-9a-fA-F]{40}$
 */
export type EthOrOasisAddress = string;

/**
 * An Oasis-style (bech32) address.
 * @pattern ^oasis1[a-z0-9]{40}$
 */
export type Address = string;

/**
 * @pattern ^-?[0-9]+$
 */
export type TextBigInt = string;

/**
 * An Oasis-style (bech32) address.
 * @pattern ^oasis1[a-z0-9]{40}$
 */
export type StakingAddress = string;

export type Runtime = typeof Runtime[keyof typeof Runtime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Runtime = {
  emerald: 'emerald',
  sapphire: 'sapphire',
  pontusxtest: 'pontusxtest',
  pontusxdev: 'pontusxdev',
  cipher: 'cipher',
} as const;

export type Layer = typeof Layer[keyof typeof Layer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Layer = {
  emerald: 'emerald',
  sapphire: 'sapphire',
  pontusxtest: 'pontusxtest',
  pontusxdev: 'pontusxdev',
  cipher: 'cipher',
  consensus: 'consensus',
} as const;





  export const getOasisNexusAPIV1 = () => {
/**
 * @summary Returns the status of indexing.
 */
const getStatus = <TData = AxiosResponse<Status>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/`,options
    );
  }

/**
 * @summary Returns a list of consensus blocks, sorted from most to least recent.
 */
const getConsensusBlocks = <TData = AxiosResponse<BlockList>>(
    params?: GetConsensusBlocksParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/blocks`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a consensus block.
 */
const getConsensusBlocksHeight = <TData = AxiosResponse<Block>>(
    height: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/blocks/${height}`,options
    );
  }

/**
 * @summary Returns a list of consensus transactions.
 */
const getConsensusTransactions = <TData = AxiosResponse<TransactionList>>(
    params?: GetConsensusTransactionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a consensus transaction.
 */
const getConsensusTransactionsTxHash = <TData = AxiosResponse<Transaction>>(
    txHash: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/transactions/${txHash}`,options
    );
  }

/**
 * @summary Returns a list of consensus events.
 */
const getConsensusEvents = <TData = AxiosResponse<ConsensusEventList>>(
    params?: GetConsensusEventsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/events`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

const getConsensusRoothashMessages = <TData = AxiosResponse<RoothashMessageList>>(
    params: GetConsensusRoothashMessagesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/roothash_messages`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of entities registered at the consensus layer.
 */
const getConsensusEntities = <TData = AxiosResponse<EntityList>>(
    params?: GetConsensusEntitiesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/entities`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns an entity registered at the consensus layer.
 */
const getConsensusEntitiesAddress = <TData = AxiosResponse<Entity>>(
    address: StakingAddress, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/entities/${address}`,options
    );
  }

/**
 * @summary Returns a list of nodes registered at the consensus layer.
 */
const getConsensusEntitiesAddressNodes = <TData = AxiosResponse<NodeList>>(
    address: StakingAddress,
    params?: GetConsensusEntitiesAddressNodesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/entities/${address}/nodes`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a node registered at the consensus layer.
 */
const getConsensusEntitiesAddressNodesNodeId = <TData = AxiosResponse<Node>>(
    address: StakingAddress,
    nodeId: Ed25519PubKey, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/entities/${address}/nodes/${nodeId}`,options
    );
  }

/**
 * @summary Returns a list of validators registered at the consensus layer (the list includes all registered entities, even those without a currently active validator node).
 */
const getConsensusValidators = <TData = AxiosResponse<ValidatorList>>(
    params?: GetConsensusValidatorsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/validators`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a validator registered at the consensus layer.
 */
const getConsensusValidatorsAddress = <TData = AxiosResponse<Validator>>(
    address: StakingAddress, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/validators/${address}`,options
    );
  }

/**
 * @summary Returns a list of consensus layer accounts.
Note that for performance reasons, the info returned by this endpoint
may be slightly stale (<2 minutes). For the most up-to-date account state,
query the single-account endpoint.

 */
const getConsensusAccounts = <TData = AxiosResponse<AccountList>>(
    params?: GetConsensusAccountsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a consensus layer account.
 */
const getConsensusAccountsAddress = <TData = AxiosResponse<Account>>(
    address: StakingAddress, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts/${address}`,options
    );
  }

/**
 * @summary Returns an account's delegations.
 */
const getConsensusAccountsAddressDelegations = <TData = AxiosResponse<DelegationList>>(
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts/${address}/delegations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of delegations to an account.
 */
const getConsensusAccountsAddressDelegationsTo = <TData = AxiosResponse<DelegationList>>(
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsToParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts/${address}/delegations_to`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns an account's debonding delegations.
 */
const getConsensusAccountsAddressDebondingDelegations = <TData = AxiosResponse<DebondingDelegationList>>(
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts/${address}/debonding_delegations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of debonding delegations to an account.
 */
const getConsensusAccountsAddressDebondingDelegationsTo = <TData = AxiosResponse<DebondingDelegationList>>(
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsToParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/accounts/${address}/debonding_delegations_to`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of consensus epochs.
 */
const getConsensusEpochs = <TData = AxiosResponse<EpochList>>(
    params?: GetConsensusEpochsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/epochs`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a consensus epoch.
 */
const getConsensusEpochsEpoch = <TData = AxiosResponse<Epoch>>(
    epoch: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/epochs/${epoch}`,options
    );
  }

/**
 * @summary Returns a list of governance proposals.
 */
const getConsensusProposals = <TData = AxiosResponse<ProposalList>>(
    params?: GetConsensusProposalsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/proposals`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a governance proposal.
 */
const getConsensusProposalsProposalId = <TData = AxiosResponse<Proposal>>(
    proposalId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/proposals/${proposalId}`,options
    );
  }

/**
 * @summary Returns a list of votes for a governance proposal.
 */
const getConsensusProposalsProposalIdVotes = <TData = AxiosResponse<ProposalVotes>>(
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/consensus/proposals/${proposalId}/votes`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of Runtime blocks.
 */
const getRuntimeBlocks = <TData = AxiosResponse<RuntimeBlockList>>(
    runtime: Runtime,
    params?: GetRuntimeBlocksParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/blocks`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of Runtime transactions.
 */
const getRuntimeTransactions = <TData = AxiosResponse<RuntimeTransactionList>>(
    runtime: Runtime,
    params?: GetRuntimeTransactionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns runtime transactions with the given transaction hash.
 */
const getRuntimeTransactionsTxHash = <TData = AxiosResponse<RuntimeTransactionList>>(
    runtime: Runtime,
    txHash: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/transactions/${txHash}`,options
    );
  }

/**
 * @summary Returns a list of runtime events.
 */
const getRuntimeEvents = <TData = AxiosResponse<RuntimeEventList>>(
    runtime: Runtime,
    params?: GetRuntimeEventsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/events`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a list of EVM (ERC-20, ...) tokens on the runtime.
 */
const getRuntimeEvmTokens = <TData = AxiosResponse<EvmTokenList>>(
    runtime: Runtime,
    params?: GetRuntimeEvmTokensParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/evm_tokens`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns info on an EVM (ERC-20, ...) token on the runtime.
 */
const getRuntimeEvmTokensAddress = <TData = AxiosResponse<EvmToken>>(
    runtime: Runtime,
    address: EthOrOasisAddress, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/evm_tokens/${address}`,options
    );
  }

/**
 * @summary Returns the list of holders of an EVM (ERC-20, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
const getRuntimeEvmTokensAddressHolders = <TData = AxiosResponse<TokenHolderList>>(
    runtime: Runtime,
    address: EthOrOasisAddress,
    params?: GetRuntimeEvmTokensAddressHoldersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/evm_tokens/${address}/holders`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns the list of non-fungible token (NFT) instances of an EVM (ERC-721, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
const getRuntimeEvmTokensAddressNfts = <TData = AxiosResponse<EvmNftList>>(
    runtime: Runtime,
    address: EthOrOasisAddress,
    params?: GetRuntimeEvmTokensAddressNftsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/evm_tokens/${address}/nfts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns the non-fungible token (NFT) instance of an EVM (ERC-721, ...) token.

 */
const getRuntimeEvmTokensAddressNftsId = <TData = AxiosResponse<EvmNft>>(
    runtime: Runtime,
    address: EthOrOasisAddress,
    id: TextBigInt, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/evm_tokens/${address}/nfts/${id}`,options
    );
  }

/**
 * @summary Returns a runtime account.
 */
const getRuntimeAccountsAddress = <TData = AxiosResponse<RuntimeAccount>>(
    runtime: Runtime,
    address: EthOrOasisAddress, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/accounts/${address}`,options
    );
  }

/**
 * @summary Returns the list of non-fungible token (NFT) instances owned by an account.

 */
const getRuntimeAccountsAddressNfts = <TData = AxiosResponse<EvmNftList>>(
    runtime: Runtime,
    address: EthOrOasisAddress,
    params?: GetRuntimeAccountsAddressNftsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/accounts/${address}/nfts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns the runtime status.
 */
const getRuntimeStatus = <TData = AxiosResponse<RuntimeStatus>>(
    runtime: Runtime, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${runtime}/status`,options
    );
  }

/**
 * @summary Returns a timeline of the transaction volume at the chosen granularity,
for either consensus or one of the paratimes.

 */
const getLayerStatsTxVolume = <TData = AxiosResponse<TxVolumeList>>(
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${layer}/stats/tx_volume`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns a (sliding) timeline of the recorded daily unique active accounts for
either consensus or one of the paratimes.

 */
const getLayerStatsActiveAccounts = <TData = AxiosResponse<ActiveAccountsList>>(
    layer: Layer,
    params?: GetLayerStatsActiveAccountsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/${layer}/stats/active_accounts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

return {getStatus,getConsensusBlocks,getConsensusBlocksHeight,getConsensusTransactions,getConsensusTransactionsTxHash,getConsensusEvents,getConsensusRoothashMessages,getConsensusEntities,getConsensusEntitiesAddress,getConsensusEntitiesAddressNodes,getConsensusEntitiesAddressNodesNodeId,getConsensusValidators,getConsensusValidatorsAddress,getConsensusAccounts,getConsensusAccountsAddress,getConsensusAccountsAddressDelegations,getConsensusAccountsAddressDelegationsTo,getConsensusAccountsAddressDebondingDelegations,getConsensusAccountsAddressDebondingDelegationsTo,getConsensusEpochs,getConsensusEpochsEpoch,getConsensusProposals,getConsensusProposalsProposalId,getConsensusProposalsProposalIdVotes,getRuntimeBlocks,getRuntimeTransactions,getRuntimeTransactionsTxHash,getRuntimeEvents,getRuntimeEvmTokens,getRuntimeEvmTokensAddress,getRuntimeEvmTokensAddressHolders,getRuntimeEvmTokensAddressNfts,getRuntimeEvmTokensAddressNftsId,getRuntimeAccountsAddress,getRuntimeAccountsAddressNfts,getRuntimeStatus,getLayerStatsTxVolume,getLayerStatsActiveAccounts}};
export type GetStatusResult = AxiosResponse<Status>
export type GetConsensusBlocksResult = AxiosResponse<BlockList>
export type GetConsensusBlocksHeightResult = AxiosResponse<Block>
export type GetConsensusTransactionsResult = AxiosResponse<TransactionList>
export type GetConsensusTransactionsTxHashResult = AxiosResponse<Transaction>
export type GetConsensusEventsResult = AxiosResponse<ConsensusEventList>
export type GetConsensusRoothashMessagesResult = AxiosResponse<RoothashMessageList>
export type GetConsensusEntitiesResult = AxiosResponse<EntityList>
export type GetConsensusEntitiesAddressResult = AxiosResponse<Entity>
export type GetConsensusEntitiesAddressNodesResult = AxiosResponse<NodeList>
export type GetConsensusEntitiesAddressNodesNodeIdResult = AxiosResponse<Node>
export type GetConsensusValidatorsResult = AxiosResponse<ValidatorList>
export type GetConsensusValidatorsAddressResult = AxiosResponse<Validator>
export type GetConsensusAccountsResult = AxiosResponse<AccountList>
export type GetConsensusAccountsAddressResult = AxiosResponse<Account>
export type GetConsensusAccountsAddressDelegationsResult = AxiosResponse<DelegationList>
export type GetConsensusAccountsAddressDelegationsToResult = AxiosResponse<DelegationList>
export type GetConsensusAccountsAddressDebondingDelegationsResult = AxiosResponse<DebondingDelegationList>
export type GetConsensusAccountsAddressDebondingDelegationsToResult = AxiosResponse<DebondingDelegationList>
export type GetConsensusEpochsResult = AxiosResponse<EpochList>
export type GetConsensusEpochsEpochResult = AxiosResponse<Epoch>
export type GetConsensusProposalsResult = AxiosResponse<ProposalList>
export type GetConsensusProposalsProposalIdResult = AxiosResponse<Proposal>
export type GetConsensusProposalsProposalIdVotesResult = AxiosResponse<ProposalVotes>
export type GetRuntimeBlocksResult = AxiosResponse<RuntimeBlockList>
export type GetRuntimeTransactionsResult = AxiosResponse<RuntimeTransactionList>
export type GetRuntimeTransactionsTxHashResult = AxiosResponse<RuntimeTransactionList>
export type GetRuntimeEventsResult = AxiosResponse<RuntimeEventList>
export type GetRuntimeEvmTokensResult = AxiosResponse<EvmTokenList>
export type GetRuntimeEvmTokensAddressResult = AxiosResponse<EvmToken>
export type GetRuntimeEvmTokensAddressHoldersResult = AxiosResponse<TokenHolderList>
export type GetRuntimeEvmTokensAddressNftsResult = AxiosResponse<EvmNftList>
export type GetRuntimeEvmTokensAddressNftsIdResult = AxiosResponse<EvmNft>
export type GetRuntimeAccountsAddressResult = AxiosResponse<RuntimeAccount>
export type GetRuntimeAccountsAddressNftsResult = AxiosResponse<EvmNftList>
export type GetRuntimeStatusResult = AxiosResponse<RuntimeStatus>
export type GetLayerStatsTxVolumeResult = AxiosResponse<TxVolumeList>
export type GetLayerStatsActiveAccountsResult = AxiosResponse<ActiveAccountsList>
