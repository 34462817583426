import { AbiCoder as f } from "ethers";
import * as e from "@oasisprotocol/client";
import * as o from "@oasisprotocol/client-rt";
import * as m from "cborg";
const g = /* @__PURE__ */ new Map([
  [
    23294,
    {
      subcallAddress: "0x0100000000000000000000000000000000000103",
      paratimeRuntimeId: "000000000000000000000000000000000000000000000000f80306c9858e7279",
      grpcUrl: "https://grpc.oasis.io"
    }
  ],
  [
    23295,
    {
      subcallAddress: "0x0100000000000000000000000000000000000103",
      paratimeRuntimeId: "000000000000000000000000000000000000000000000000a6d1e3ebf60dff6c",
      grpcUrl: "https://testnet.grpc.oasis.io"
    }
  ]
]), u = f.defaultAbiCoder(), p = async (s) => await e.address.fromData(
  o.address.V0_SECP256K1ETH_CONTEXT_IDENTIFIER,
  o.address.V0_SECP256K1ETH_CONTEXT_VERSION,
  e.misc.fromHex(s.replace("0x", ""))
), c = (s) => {
  const t = Number(s);
  if (!g.has(t))
    throw new Error(
      `Invalid [chainId], supported chain ids are: ${Array.from(g.keys()).join(", ")}`
    );
  return g.get(t);
}, A = (s, t, n) => {
  const { subcallAddress: r } = c(s), a = u.encode(
    ["string", "bytes"],
    [
      "consensus.Delegate",
      m.encode({
        amount: [e.quantity.fromBigInt(n), o.token.NATIVE_DENOMINATION],
        to: e.staking.addressFromBech32(t)
      })
    ]
  );
  return {
    to: r,
    data: a
  };
}, T = (s, t, n) => {
  const { subcallAddress: r } = c(s), a = u.encode(
    ["string", "bytes"],
    [
      "consensus.Undelegate",
      m.encode({
        shares: e.quantity.fromBigInt(n),
        from: e.staking.addressFromBech32(t)
      })
    ]
  );
  return {
    to: r,
    data: a
  };
}, E = "consensus.Delegations", N = "consensus.Undelegations", y = async (s, t) => {
  const { paratimeRuntimeId: n, grpcUrl: r } = c(s), a = await p(t);
  return (await new o.wrapper.QueryWrapper(e.misc.fromHex(n), E).setArgs({ from: a }).query(new e.client.NodeInternal(r))).map(({ to: i, shares: d }) => ({
    to: e.staking.addressToBech32(i),
    shares: e.quantity.toBigInt(d)
  }));
}, w = async (s, t) => {
  const { paratimeRuntimeId: n, grpcUrl: r } = c(s), a = await p(t);
  return (await new o.wrapper.QueryWrapper(e.misc.fromHex(n), N).setArgs({ to: a }).query(new e.client.NodeInternal(r))).map(({ from: i, shares: d, epoch: I }) => ({
    from: e.staking.addressToBech32(i),
    shares: e.quantity.toBigInt(d),
    epoch: BigInt(I)
  }));
};
export {
  A as consensusDelegate,
  T as consensusUndelegate,
  y as getDelegations,
  w as getUndelegations
};
